import { useContext, useEffect, useRef, useState } from 'react';
import { MdAdd, MdEdit, MdSettings } from 'react-icons/md';
import { toast } from 'react-toastify';
import { AppStateContext, DataContext, UserContext } from '../App';
import InteractiveMap from '../components/InteractiveMap';
import MapModal from '../components/Modals/MapModal';
import SensorModal from '../components/Modals/SensorModal';
import { APP_API_BASE_PATH } from '../config';
import {
	DisplayParameter,
	MapColorScheme,
	MapListApiResponse,
	Sensor,
	SensorMap,
} from '../types';
import {
	displayParameterToName,
	parseDisplayParamter as parseDisplayParameter,
} from '../utils';

interface MapSettings {
	colorScheme: MapColorScheme | undefined;
	displayParameter: DisplayParameter | undefined;
	currentMapId: number | undefined;
}

function MapPage() {
	const data = useContext(DataContext);
	const { isPhone } = useContext(AppStateContext);
	const { userRights } = useContext(UserContext);

	const [mapList, setMapList] = useState<SensorMap[]>([]);
	const [displayedMap, setDisplayedMap] = useState<number | null>(null);
	const displayedMapRef = useRef(displayedMap);
	displayedMapRef.current = displayedMap;

	const [displayParameter, setDisplayParameter] = useState<DisplayParameter>(
		DisplayParameter.Name
	);
	const [colorScheme, setColorScheme] = useState<MapColorScheme>(
		MapColorScheme.Absolute
	);

	const [isLoadingMap, setIsLoadingMap] = useState(false);

	const fetchMapList = (currentMapId?: number) => {
		setIsLoadingMap(true);
		fetch(APP_API_BASE_PATH + '/maplist', { credentials: 'include' })
			.then((data) => data.json())
			.then((parsed_data) => {
				const response = parsed_data as MapListApiResponse;
				if (response.status === 'err') throw new Error(response.message);

				setMapList(response.data);
				if (response.data.length > 0 && displayedMapRef.current === null) {
					let currentMap = response.data[0];
					if (currentMapId !== undefined) {
						const search = response.data.find((m) => m.map_id === currentMapId);
						if (search !== undefined) currentMap = search;
					}
					setDisplayedMap(currentMap.map_id);
					setDetailMap(currentMap);
				}
				setIsLoadingMap(false);
			})
			.catch((e: Error) => {
				console.error(e);
				toast.error(e.message);
			});
	};

	useEffect(() => {
		const data = window.localStorage.getItem('map_settings');
		if (data) {
			const {
				colorScheme: cs,
				displayParameter: dp,
				currentMapId,
			} = JSON.parse(data) as MapSettings;
			if (cs !== undefined) setColorScheme(cs);
			if (dp !== undefined) setDisplayParameter(dp);
			fetchMapList(currentMapId);
			return;
		}
		fetchMapList();
	}, []);

	useEffect(() => {
		if (isPhone) setDisplayOptions(false);
	}, [isPhone]);

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [detailMap, setDetailMap] = useState<SensorMap | null>(null);

	const [isNewMap, setIsNewMap] = useState(false);

	const [sensorModalOpen, setSensorModalOpen] = useState(false);
	const [detailSensor, setDetailSensor] = useState<Sensor | null>(null);

	const [displayOptions, setDisplayOptions] = useState(false);

	return (
		<>
			{userRights?.can_edit_maps && (
				<div className="floating_buttons">
					{detailMap && mapList.length > 0 && (
						<button
							onClick={() => {
								setIsNewMap(false);
								setModalIsOpen(true);
							}}
						>
							<MdEdit size={25} />
						</button>
					)}
					<button
						onClick={() => {
							setIsNewMap(true);
							setModalIsOpen(true);
						}}
					>
						<MdAdd />
					</button>
				</div>
			)}
			<div className="h-14 w-full flex flex-row justify-between items-start z-50 relative border-b-2 border-accent-normal">
				<div className="h-full w-full flex flex-row flex-grow overflow-auto overflow-y-hidden">
					{mapList.map((m, idx) => {
						return (
							<button
								onClick={() => {
									setDisplayedMap(m.map_id);
									setDetailMap(m);
									const data: MapSettings = {
										colorScheme,
										displayParameter,
										currentMapId: m.map_id,
									};

									window.localStorage.setItem(
										'map_settings',
										JSON.stringify(data)
									);
								}}
								className={
									'map_button' + (displayedMap === m.map_id ? ' active' : '')
								}
								key={idx}
							>
								{m.map_name}
							</button>
						);
					})}
				</div>
				<div className="flex flex-row items-center gap-1 relative pl-3">
					<div
						className={
							'map_options_tray' + (!displayOptions && isPhone ? ' hidden' : '')
						}
					>
						<select
							className="p-2"
							value={colorScheme}
							onChange={(e) => {
								const cs = parseInt(e.target.value) as MapColorScheme;
								setColorScheme(cs);

								const data: MapSettings = {
									colorScheme: cs,
									displayParameter,
									currentMapId: displayedMap ?? 0,
								};

								window.localStorage.setItem(
									'map_settings',
									JSON.stringify(data)
								);
							}}
						>
							<option value={MapColorScheme.Absolute}>Absolutní barvy</option>
							<option value={MapColorScheme.Relative}>Relativní barvy</option>
						</select>
						<select
							className="p-2"
							value={displayParameter}
							onChange={(e) => {
								const parsedDisplayParameter = parseDisplayParameter(
									parseInt(e.target.value)
								);
								if (parsedDisplayParameter !== undefined) {
									setDisplayParameter(parsedDisplayParameter);
									window.localStorage.setItem(
										'map_settings',
										JSON.stringify({
											colorScheme,
											displayParameter: parsedDisplayParameter,
										})
									);
								}
							}}
						>
							<option value={DisplayParameter.Name}>
								{displayParameterToName(DisplayParameter.Name)}
							</option>
							<option value={DisplayParameter.Temperature}>
								{displayParameterToName(DisplayParameter.Temperature)}
							</option>
							<option value={DisplayParameter.Humidity}>
								{displayParameterToName(DisplayParameter.Humidity)}
							</option>
							<option value={DisplayParameter.RSSI}>
								{displayParameterToName(DisplayParameter.RSSI)}
							</option>
							<option value={DisplayParameter.Voltage}>
								{displayParameterToName(DisplayParameter.Voltage)}
							</option>
						</select>
					</div>
					{isPhone && (
						<MdSettings
							onClick={(_) => {
								setDisplayOptions(!displayOptions);
							}}
							size={40}
							className="text-white bg-accent-normal ml-auto p-2 cursor-pointer hover:bg-accent-light rounded-full"
						/>
					)}
				</div>
			</div>
			{isLoadingMap || mapList.length > 0 ? (
				<InteractiveMap
					map={mapList.find((m) => m.map_id === displayedMap) ?? null}
					isLoading={isLoadingMap}
					displayParameter={displayParameter}
					colorScheme={colorScheme}
					onItemClicked={(sensorId) => {
						const sensor = data.sensorList.find(
							(s) => 'sensor-' + s.sensor_id === sensorId
						);
						if (sensor) {
							setDetailSensor(sensor);
							setSensorModalOpen(true);
						}
					}}
				/>
			) : (
				<div className="w-full h-full flex flex-col items-center text-3xl bg-light-normal">
					<span className="pt-20">
						Žádná mapa -{' '}
						<span
							className="link"
							onClick={() => {
								setIsNewMap(true);
								setModalIsOpen(true);
							}}
						>
							přidat
						</span>
					</span>
				</div>
			)}
			<MapModal
				detailMap={detailMap}
				fetchMapList={fetchMapList}
				isNewMap={isNewMap}
				isOpen={modalIsOpen}
				setIsOpen={setModalIsOpen}
				isOnPhone={isPhone}
			/>
			{detailSensor && (
				<SensorModal
					isOpen={sensorModalOpen}
					setIsOpen={setSensorModalOpen}
					sensor={detailSensor}
					//onClose={() => fetchMapList()}
					isOnPhone={isPhone}
					userRights={userRights!}
				/>
			)}
		</>
	);
}

export default MapPage;
