import { useContext } from 'react';
import { FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import { UserContext } from '../App';
import { AppTheme } from '../types';

export interface CustomModalProps {
	isOpen: boolean;
	title: string;
	onClose?: () => void;
	onOpen?: () => void;
	handleClose: () => void;
	content: JSX.Element;
	footer: JSX.Element | null;
	isFullscreen?: boolean;
	style?: Modal.Styles;
}

const CustomModal: React.FC<CustomModalProps> = ({
	isOpen,
	onClose,
	onOpen,
	handleClose,
	title,
	content,
	footer,
	isFullscreen = false,
	style,
}) => {
	const { userData } = useContext(UserContext);
	const colorScheme = userData?.color_scheme || AppTheme.Light;

	return (
		<Modal
			isOpen={isOpen}
			style={{
				content: {
					padding: '20px',
					inset: isFullscreen ? 0 : '40px',
					backgroundColor: colorScheme === AppTheme.Light ? 'white' : '#1d1d1d',
					...style?.content,
				},
				overlay: { zIndex: 10000, backgroundColor: colorScheme === AppTheme.Light ? 'rgba(255, 255, 255, 0.75)' : 'rgba(0, 0, 0, 0.75)',...style?.overlay },
			}}
			onAfterClose={onClose}
			onAfterOpen={onOpen}
		>
			<div className={"w-full h-full relative flex flex-col" + (colorScheme === AppTheme.Dark ? " dark" : "")}>
				<div className="w-full flex flex-row justify-between items-center flex-shrink mb-5">
					<h2 className="text-3xl">{title}</h2>
					<button
						onClick={() => handleClose()}
						className="cursor-pointer p-2 border-none bg-white hover:bg-white text-black hover:text-gray-500 dark:bg-dark-normal dark:text-white dark:hover:text-gray-300"
					>
						<FaTimes size={20} />
					</button>
				</div>
				{content}
				<div className="w-full flex-shrink mt-3 flex flex-row justify-end gap-2">
					{footer}
				</div>
			</div>
		</Modal>
	);
};

export default CustomModal;
