import { useEffect, useState } from 'react';
import { Bars } from 'react-loader-spinner';
import { APP_API_BASE_PATH } from '../config';
import {
	COLOR_SCHEME_TABLES,
	HUMIDITY_RELATIVE_COLORS,
	RSSI_RELATIVE_COLORS,
	TEMPERATURE_RELATIVE_COLORS,
	VOLTAGE_RELATIVE_COLORS,
} from '../constants';
import {
	DisplayParameter,
	MapColorScheme,
	SensorMap,
} from '../types';
import {
	displayParameterToKey,
	getColorGradient,
	getSensorValue,
} from '../utils';
import DisplayedSensorSVG from './DisplayedSensorSVG';
import InteractiveView from './InteractiveView';

export interface InteractiveMapProps {
	map: SensorMap | null;
	displayParameter: DisplayParameter;
	colorScheme?: MapColorScheme;
	isEditable?: boolean;
	isLoading?: boolean;
	onItemClicked?: (itemId: string) => void;
	onItemMoved?: (itemId: string, newPosition: {x: number, y: number}) => void;
}

export default function InteractiveMap({
	map,
	displayParameter,
	colorScheme,
	isEditable = false,
	isLoading = false,
	onItemClicked = () => {},
	onItemMoved = () => {},
}: InteractiveMapProps) {

	type SensorColors = {
		[sensorId: number]: string;
	};

	const [sensorColors, setSensorColors] = useState<SensorColors>({});

	useEffect(() => {
		if(!map?.sensors) return;
		if(
			displayParameter === DisplayParameter.Name ||
			colorScheme === undefined
		) {
			const colors: SensorColors = {};
			for(const s of map.sensors) {
				colors[s.sensor.sensor_id] = 'black';
			}
			setSensorColors(colors);
			return;
		}

		const flatValues = map.sensors
			.filter((s) => s.data !== undefined)
			.map((s) => getSensorValue(s.data!, displayParameter));

		const minVal = Math.min(...flatValues);
		const maxVal = Math.max(...flatValues);

		const colorGradient: SensorColors = {};

		const colors =
			displayParameter === DisplayParameter.Temperature
				? TEMPERATURE_RELATIVE_COLORS
				: displayParameter === DisplayParameter.Humidity
					? HUMIDITY_RELATIVE_COLORS
					: displayParameter === DisplayParameter.RSSI
						? RSSI_RELATIVE_COLORS
						: VOLTAGE_RELATIVE_COLORS;

		for(const s of map.sensors) {
			if(!s.data) continue;
			const val = s.data[displayParameterToKey(displayParameter)].value;
			if(colorScheme === MapColorScheme.Relative) {
				const i = (val - minVal) / (maxVal - minVal);

				colorGradient[s.sensor.sensor_id] = getColorGradient(
					colors[0],
					colors[1],
					i
				);
				continue;
			}

			let selectedColor = 'black';
			for(const [value, color] of COLOR_SCHEME_TABLES[displayParameter - 1]) {
				if(val < value) {
					selectedColor = color;
					break;
				}
			}
			colorGradient[s.sensor.sensor_id] = selectedColor;
		}
		setSensorColors(colorGradient);
	}, [displayParameter, colorScheme, map?.sensors]);

	if(isLoading)
		return (
			<div className="h-full relative flex flex-col p-2 bg-white border border-gray-300">
				<Bars
					height="80"
					width="80"
					color="#11547a"
					ariaLabel="Loading..."
					wrapperStyle={{}}
					wrapperClass="loader"
					visible={true}
				/>
			</div>
		);
	if(!map) return null;
	return (

		<InteractiveView
			canMoveItems={isEditable}
			items={map.sensors.map(s => {
				return {
					id: 'sensor-' + s.sensor.sensor_id.toString(),
					element: <DisplayedSensorSVG
						sensor={s}
						color={sensorColors[s.sensor.sensor_id]}
						displayParameter={displayParameter}
					/>,
					x: s.pos_x,
					y: s.pos_y,
				}
			})}
			backgroundItems={[{
				id: 'map',
				element: <img
					src={APP_API_BASE_PATH + '/mapimage/' + map.map_id}
					alt="Map"
					width={map.image_width}
					height={map.image_height}
					style={{
						width: 1000,
						//height: 1000,
					}}
				//className="absolute top-0 left-0 select-none pointer-events-none"
				/>,
				x: 0,
				y: 0,
			}]}
			options={{
				initialZoom: Math.min(1000 / map.image_width, 1000 / map.image_height),
			}}
			onItemClicked={(item) => onItemClicked(item.id)}
			onItemMoved={(item, newPosition) => onItemMoved(item.id, newPosition)}
		/>
	);
};
