import { useContext, useEffect, useState } from 'react';
import { FaLock, FaUser } from 'react-icons/fa';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../App';
import PasswordInput from '../components/PasswordInput';
import { APP_API_BASE_PATH } from '../config';
import { ApiResponse, ConnectionDetails, GenericApiResponse } from '../types';
import { verifyPassword } from '../utils';

export interface SetupPageProps {}

enum SetupSteps {
	Welcome,
	SetupMQTT,
	CreateAdminUser,
	SetAdminPassword,
	Finish,
}

const AFTER_LOGIN_NAVIGATE = '/';
const SetupPage: React.FC<SetupPageProps> = () => {
	const navigate = useNavigate();
	const { isInited, setIsInited } = useContext(AuthContext);
	const [password, setPassword] = useState('');
	const [username, setUsername] = useState('');
	const [passwordVerify, setPasswordVerify] = useState('');

	const [connDetails, setConnDetails] = useState<ConnectionDetails | null>(
		null
	);

	const [setupStep, setSetupStep] = useState(SetupSteps.Welcome);
	const stepMessages = [
		'',
		'Nastavení MQTT serveru',
		'Nastavte si hlavní správcovský účet',
		'Vytvořte heslo správce hesel',
		'Hotovo',
	];

	useEffect(() => {
		fetch(APP_API_BASE_PATH + '/connectiondetails')
			.then((d) => d.json())
			.then((d: ApiResponse<ConnectionDetails>) => {
				if (d.status === 'err') {
					console.error(d.message);
					return toast.error(d.message);
				}
				d.data && setConnDetails(d.data);
			});
	}, []);

	const createAdminUser = async (username: string): Promise<string | null> => {
		const payload = {
			username,
		};
		return new Promise((res, rej) => {
			fetch(APP_API_BASE_PATH + '/createuser', {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			})
				.then((d) => d.json())
				.then((d: GenericApiResponse) => {
					if (d.status === 'err') {
						console.error(d.message);
						toast.error('Server vrátil chybu: ' + d.message);
						return rej(d.message);
					}
					if (d.status === 'ok') {
						if (d.data !== undefined) {
							// toast.success('Byl vytvořen nový uživatel');
							res(d.data.token);
						} else {
							toast.error('Nepodařilo se vytvořit nového uživatele');
							res(null);
						}
					}
				});
		});
	};

	const setAdminPassword = async (password: string, token: string) => {
		const payload = {
			password,
			token,
		};
		return new Promise((res, rej) => {
			fetch(APP_API_BASE_PATH + '/setpassword', {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			})
				.then((d) => d.json())
				.then((d: GenericApiResponse) => {
					if (d.status === 'err') {
						console.error(d.message);
						toast.error(d.message);
						return rej(1);
					}
					if (d.status === 'ok') {
						// toast.success('Heslo bylo úspěšně nastaveno');
						res(0);
					} else {
						toast.error('Nepodařilo se nastavit heslo');
						rej(1);
					}
				});
		});
	};

	if (isInited) return <Navigate to={AFTER_LOGIN_NAVIGATE} />;
	return (
		<div className="w-full h-full flex flex-col justify-center items-center">
			<div
				className="bg-white p-10 shadow-lg"
				style={{ maxWidth: '500px', width: '100%' }}
			>
				<h1 className="text-3xl mb-5 text-accent-dark">IQAROS DASHBOARD</h1>
				{setupStep === SetupSteps.Welcome && (
					<div>
						<p>Vítejte v aplikaci IQAROS DASHBOARD!</p>
						<p>Tento průvodce Vám pomůže vše nastavit.</p>
					</div>
				)}

				{setupStep > SetupSteps.Welcome && (
					<div className="text-lg mb-2">
						{setupStep}) {stepMessages[setupStep]}
					</div>
				)}
				<div>
					<div className="flex flex-col gap-2">
						{setupStep === SetupSteps.SetupMQTT && (
							<div>
								<p>
									Aby měla aplikace přístup k datům ze senzorů, je třeba správně
									nastavit gateway.
								</p>
								<p className="mt-5">
									V konfiguračním panelu IQAROS gatewaye v záložce{' '}
									<b>Cloudové služby</b> změňte nastavení MQTT na:
								</p>
								<table className="mt-3">
									<tbody>
										<tr>
											<td>Broker:</td>
											<td>{connDetails?.brokerIp}</td>
										</tr>
										<tr>
											<td>Klient ID:</td>
											<td>{connDetails?.clientId}</td>
										</tr>
										<tr>
											<td>Topic pro dotaz:</td>
											<td>{connDetails?.pushTopic}</td>
										</tr>
										<tr>
											<td>Topic pro odpověď:</td>
											<td>{connDetails?.pullTopic}</td>
										</tr>
										<tr>
											<td>Uživatel:</td>
											<td>{connDetails?.mqttUser}</td>
										</tr>
										<tr>
											<td>Heslo:</td>
											<td>{connDetails?.mqttPasswrod}</td>
										</tr>
									</tbody>
								</table>
								{/* <p>(TODO) Zde se zobrazí odpovědi od senzorů:</p> */}
							</div>
						)}
						{setupStep === SetupSteps.CreateAdminUser && (
							<>
								<div className="italic">
									Tímto uživatelským jménem se budete přihlašovat do aplikace.
								</div>
								<div className="flex flex-row items-center bg-white border-gray-300 border flex-1">
									<FaUser size={18} className="m-2" />
									<input
										type="text"
										value={username}
										className="outline-none border-0"
										placeholder="admin"
										onChange={(e) => setUsername(e.target.value)}
									/>
								</div>
							</>
						)}
						{setupStep === SetupSteps.SetAdminPassword && (
							<>
								<div className="italic">
									Heslo musí obsahovat alespoň 1 písmeno, alespoň 1 číslo a musí
									mít alespoň 6 znaků.
								</div>
								<div className="flex flex-row items-center bg-white border-gray-300 border flex-1">
									<FaLock size={18} className="m-2" />
									<PasswordInput
										value={password}
										autoComplete="new-password"
										placeholder="Heslo"
										isStandalone={false}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
								<div className="flex flex-row items-center bg-white border-gray-300 border flex-1">
									<FaLock size={18} className="m-2" />
									<PasswordInput
										value={passwordVerify}
										autoComplete="new-password"
										isStandalone={false}
										placeholder="Heslo znovu"
										onChange={(e) => setPasswordVerify(e.target.value)}
									/>
								</div>
							</>
						)}
						{setupStep === SetupSteps.Finish && (
							<div>
								Vše je hotové, pro první přihlášení použijte uživatelské jméno{' '}
								<b>{username || 'admin'}</b> a vaše heslo.
							</div>
						)}
						<div className="mt-3 flex flex-row gap-1">
							{setupStep > SetupSteps.Welcome && (
								<button
									className="bg-white text-accent-normal border-accent-normal border-2 border-solid rounded-full px-3 py-1 hover:border-accent-light"
									onClick={() => setSetupStep(Math.max(setupStep - 1, 0))}
								>
									Zpět
								</button>
							)}
							<button
								className="bg-accent-normal text-white rounded-full px-3 py-1 border-2 border-transparent hover:bg-accent-light"
								onClick={async () => {
									// Druhy krok - tvorba hesla
									if (setupStep === SetupSteps.SetAdminPassword) {
										if (password.length === 0 || passwordVerify.length === 0)
											return toast.error('Pole nesmí být prázdná');
										if (!verifyPassword(password))
											return toast.error(
												'Nebyly splněny podmínky pro tvorbu hesla'
											);
										if (password !== passwordVerify)
											return toast.error('Hesla se neshodují');
										try {
											const token = await createAdminUser(username || 'admin');
											if (!token) return;
											await setAdminPassword(password, token);
											toast.success('Administrátorský účet byl vytvořen');
										} catch (e) {
											return;
										}
									}
									// Posledni krok -> prihlaseni
									else if (setupStep === SetupSteps.Finish) {
										setIsInited(true);
										navigate('/login');
									}
									setSetupStep(
										Math.min(setupStep + 1, stepMessages.length - 1)
									);
								}}
							>
								{setupStep === stepMessages.length - 1 ? 'Přihlášení' : 'Další'}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SetupPage;
