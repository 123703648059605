import { useMemo, useRef, useState } from 'react';
import { MdZoomIn, MdZoomOut } from 'react-icons/md';
import {
	getTouchCenter,
	getTouchDistance,
} from '../utils';

export interface InteractiveViewItem {
	id: string;
	element: JSX.Element;
	x: number;
	y: number;
	canBeMoved?: boolean;
	canBeClicked?: boolean;
}

export interface InteractiveViewOptions {
	initialZoom: number;
	minZoom: number;
	maxZoom: number;
	initialX: number;
	minX: number;
	maxX: number;
	initialY: number;
	minY: number;
	maxY: number;
	scrollSensitivity: number;
	buttonZoomStep: number;
	keyboardPanStep: number;
}

export interface InteractiveViewProps {
	items?: InteractiveViewItem[];
	backgroundItems?: InteractiveViewItem[];
	options?: Partial<InteractiveViewOptions>;
	onItemClicked?: (item: InteractiveViewItem) => void;
	onItemMoved?: (item: InteractiveViewItem, newPosition: {x: number, y: number}) => void;
	canMoveItems?: boolean;
}

export default function InteractiveView({
	items = [],
	backgroundItems = [],
	options,
	onItemClicked = () => {},
	onItemMoved = () => {},
	canMoveItems = false
}: InteractiveViewProps) {

	const viewOptions = {
		initialZoom: 5,
		minZoom: 0.1,
		maxZoom: 100,
		initialX: 50,
		minX: -100,
		maxX: 100,
		initialY: 100,
		minY: -100,
		maxY: 100,
		scrollSensitivity: 1.2,
		buttonZoomStep: 100,
		keyboardPanStep: 30,
		...options
	} as InteractiveViewOptions;

	const [xOffset, setXOffset] = useState(viewOptions.initialX);
	const [yOffset, setYOffset] = useState(viewOptions.initialY);
	const [xScale, setXScale] = useState(viewOptions.initialZoom);
	const [yScale, setYScale] = useState(viewOptions.initialZoom);

	const [moving, setMoving] = useState(false);

	const view = useRef<HTMLDivElement>(null);

	const evCache = useRef<React.PointerEvent[]>([]);
	const evPrevCache = useRef<{[pointerId: number]: React.PointerEvent}>({});
	const lastDistance = useRef(-1);

	const onPointerDown = (e: React.PointerEvent) => {
		//this system is partially based on this: https://developer.mozilla.org/en-US/docs/Web/API/Pointer_events/Pinch_zoom_gestures
		evCache.current.push(e);

		setMoving(true);
		evPrevCache.current[e.pointerId] = e;
		//this.svg.style.cursor = 'grabbing';

		//console.log(`clientX: ${e.clientX}, clientY: ${e.clientY}`);
		// console.log('pointer down from map');
	}

	const onPointerUp = (e: React.PointerEvent) => {
		if(moving) {
			setMoving(false);
			//this.svg.style.cursor = 'grab';
		}
		view.current?.releasePointerCapture(e.pointerId);
		delete evPrevCache.current[e.pointerId];
		evCache.current = evCache.current.filter(ev => ev.pointerId !== e.pointerId);
		if(evCache.current.length < 2) {
			lastDistance.current = -1;
			//this.updateViewBox();
		}

		// console.log('pointer up from map');
	}

	const onPointerMove = (e: React.PointerEvent) => {
		if(!view.current?.hasPointerCapture(e.pointerId)) view.current?.setPointerCapture(e.pointerId);
		for(let i = 0; i < evCache.current.length; i++) {
			if(e.pointerId === evCache.current[i].pointerId) {
				evCache.current[i] = e;
				if(moving) {
					const movementX = (evCache.current[i].clientX - evPrevCache.current[e.pointerId].clientX);
					const movementY = (evCache.current[i].clientY - evPrevCache.current[e.pointerId].clientY);
					setXOffset(xOffset + movementX / evCache.current.length);
					setYOffset(yOffset + movementY / evCache.current.length);
				}
				break;
			}
		}
		evPrevCache.current[e.pointerId] = e;

		if(evCache.current.length === 2) {
			// Calculate the distance between the two pointers
			const curDist = getTouchDistance(evCache.current[0].clientX, evCache.current[0].clientY, evCache.current[1].clientX, evCache.current[1].clientY);
			
			if(lastDistance.current > 0) {
				const center = getTouchCenter(evCache.current[0].clientX, evCache.current[0].clientY, evCache.current[1].clientX, evCache.current[1].clientY);
				zoom(curDist, center.x, center.y, true);
			}
			lastDistance.current = curDist;
		}
	}

	const onWheel = (e: React.WheelEvent) => {
		e.stopPropagation();
		e.preventDefault();
		zoom(e.deltaY, e.clientX, e.clientY);
	}

	const zoom = (delta: number, x: number, y: number, touch = false) => {

		//subtract element client offset
		const el = view.current!.getBoundingClientRect();
		x -= el.x;
		y -= el.y;

		const scale = touch ?
			delta / lastDistance.current : //for pinch zoom gestures
			delta < 0 ? viewOptions.scrollSensitivity : 1 / viewOptions.scrollSensitivity; //for mouse wheel

		const newXScale = xScale * scale;
		if(newXScale < viewOptions.maxZoom && newXScale > viewOptions.minZoom) {
			setXScale(newXScale);
			setXOffset(x - (x - xOffset) * scale);
		}
		const newYScale = yScale * scale;
		if(newYScale < viewOptions.maxZoom && newYScale > viewOptions.minZoom) {
			setYScale(newYScale);
			setYOffset(y - (y - yOffset) * scale);
		}
		//console.log(`Xscale: ${newXScale}, YScale: ${newYScale}, x: ${x}, y: ${y}`);
	}

	const refs = useRef<{[id: string]: HTMLElement}>({});
	const [itemMoving, setItemMoving] = useState<string | null>(null);
	const lastPos = useRef<React.PointerEvent>();

	const itemsCache = useMemo(() => {
		//console.log('rerendering items');
		return items.map((item, i) => {
			return (
				<div
					key={i}
					id={item.id}
					ref={el => {
						if(el) {
							refs.current[item.id] = el;
						}
						else {
							delete refs.current[item.id];
						}
					}}
					className="absolute"
					style={{
						left: item.x * xScale,
						top: item.y * yScale,
						width: '0',
						userSelect: 'none',
						pointerEvents: item.canBeClicked === false ? 'none' : 'all',
						//background: '#00f5',
					}}
					onClick={() => {if(!canMoveItems && item.canBeClicked !== false) onItemClicked(item)}}
					onPointerDown={e => {
						if(!canMoveItems || item.canBeMoved === false) return;
						e.stopPropagation();
						setItemMoving(item.id);
						lastPos.current = e;
						//console.log('pointer down from item', item.id);
					}}
					onPointerUp={e => {
						if(!canMoveItems || item.canBeMoved === false) return;
						refs.current[item.id].releasePointerCapture(e.pointerId);
						e.stopPropagation();
						setItemMoving(null);
						onItemMoved(item, {
							x: parseFloat(refs.current[item.id].style.left) / xScale,
							y: parseFloat(refs.current[item.id].style.top) / yScale
						});
						//console.log('pointer up from item', item.id)
					}}
					onPointerMove={e => { 
						if(!refs.current[item.id]?.hasPointerCapture(e.pointerId)) {refs.current[item.id].setPointerCapture(e.pointerId);/*console.log('capturing pointer for id', item.id);*/};
						if(itemMoving) {
							e.preventDefault();
							e.stopPropagation();
							// setItemMoving({
							// 	element: itemMoving.element,
							// 	id: itemMoving.id,
							// 	x: itemMoving.x + e.movementX,
							// 	y: itemMoving.y + e.movementY,
							// });
							//setItemMoving(item.id);
							// setMovingX(movingX + e.movementX);
							// setMovingY(movingY + e.movementY);
							//const el = view.current!.getBoundingClientRect();
							//const pos = window.getComputedStyle(refs.current[item.id]);

							//refs.current[item.id].style.left = parseFloat(refs.current[item.id].style.left) + e.movementX / xScale + 'px';
							//refs.current[item.id].style.top = parseFloat(refs.current[item.id].style.top) + e.movementY / yScale + 'px';

							//!!!! šulix největší, tohle mi trvalo snad celý odpoledne!!
							refs.current[item.id].style.left = parseFloat(refs.current[item.id].style.left) + (e.clientX - lastPos.current!.clientX) /*/ xScale*/ + 'px';
							refs.current[item.id].style.top = parseFloat(refs.current[item.id].style.top) + (e.clientY - lastPos.current!.clientY) /*/ yScale*/ + 'px';
							
							//refs.current[item.id].style.left = e.clientX - el.x - xOffset + 'px';

							lastPos.current = e;

							//console.log('pointer move from item', item.id);
						}
					}}
				>
					{item.element}
				</div>
			);
		})
	}, [items, canMoveItems, itemMoving, xScale, yScale, onItemClicked, onItemMoved]);

	const backgroundItemCache = useMemo(() => {
		return backgroundItems.map((item, i) => {
			return (
				<div
					key={i}
					id={item.id}
					className="absolute"
					style={{
						left: item.x,
						top: item.y,
						userSelect: 'none',
						pointerEvents: 'none',
						//background: '#00f5',
					}}
				>
					{item.element}
				</div>
			);
		})
	}, [backgroundItems]);

	
	return (
		<div className="h-full relative flex flex-col bg-white dark:bg-dark-normal border border-gray-300 dark:border-dark-lighter overflow-hidden"
			ref={view}
			onPointerDown={e => onPointerDown(e)}
			onPointerUp={e => onPointerUp(e)}
			onPointerMove={e => onPointerMove(e)}
			onWheel={e => onWheel(e)}
			style={{
				cursor: moving ? 'grabbing' : 'grab',
				touchAction: 'none',
			}}>
			<div className="absolute top-3 right-3 z-20 flex flex-row gap-1">
				<button
					onClick={() => {
						const el = view.current!.getBoundingClientRect();
						zoom(-viewOptions.buttonZoomStep, el.width / 2, el.height / 2);
					}}
				>
					<MdZoomIn size={30} />
				</button>
				<button
					onClick={() => {
						const el = view.current!.getBoundingClientRect();
						zoom(viewOptions.buttonZoomStep, el.width / 2, el.height / 2);
					}}
				>
					<MdZoomOut size={30} />
				</button>
			</div>
			<div className="absolute z-9"
				style={{
					// left: xOffset,
					// top: yOffset,
					transform: `translate(${xOffset}px, ${yOffset}px) scale(${xScale}, ${yScale})`,
					transformOrigin: 'top left',//`${xOffset}px ${yOffset}px`,
					// transition: 'transform 0.2s',
					background: '#0f05',
				}}>
				{backgroundItemCache}
			</div>
			<div className="absolute z-10"
				style={{
					left: xOffset,
					top: yOffset,
					//background: '#f005',
				}}>
				{itemsCache}
			</div>
		</div>
	);
};
