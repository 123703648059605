import { useEffect, useState } from 'react';
import { FaKey, FaLock } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PasswordInput from '../components/PasswordInput';
import { APP_API_BASE_PATH } from '../config';
import { GenericApiResponse } from '../types';
import { verifyPassword } from '../utils';

export default function SetPasswordPage() {
	const navigate = useNavigate();
	const [username, setUsername] = useState('...');
	const [password, setPassword] = useState('');
	const [passwordVerify, setPasswordVerify] = useState('');
	const [token, setToken] = useState('');
	const [tokenInput, setTokenInput] = useState('');
	const [tokenValid, setTokenValid] = useState(false);

	const changePassword = async (password: string, token: string) => {

		if(password.length === 0 || passwordVerify.length === 0)
			return toast.error('Pole nesmí být prázdná');
		if(!verifyPassword(password))
			return toast.error(
				'Nebyly splněny podmínky pro tvorbu hesla'
			);
		if(password !== passwordVerify)
			return toast.error('Hesla se neshodují');

		const payload = {
			password,
			token
		};

		fetch(APP_API_BASE_PATH + '/setpassword', {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		})
			.then((d) => d.json())
			.then((d: GenericApiResponse) => {
				if(d.status === 'ok') {
					toast.success('Heslo bylo úspěšně nastaveno');
					navigate('/login');
				}
				else {
					console.error(d.message);
					toast.error(d.message);
					toast.error('Nepodařilo se nastavit heslo')
				}
			});
	};

	useEffect(() => {
		setToken(new URLSearchParams(window.location.search).get('token') ?? '');
	}, []);

	useEffect(() => {
		if(token.length === 0 || token === "") return;
		const payload = {
			token: token,
			dryRun: true,
		};
		fetch(APP_API_BASE_PATH + '/setpassword', {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		})
			.then((d) => d.json())
			.then((d: GenericApiResponse) => {
				if(d.status === 'ok') {
					setUsername(d.data.username);
					setTokenValid(true);
				}
				else {
					//navigate('/login');
					setTokenValid(false);
					if(token) toast.error('Neplatný token')
				}
			});
	}, [token]);

	// if(!token) return <Navigate to={'/login'} />;

	return (
		<div className="w-full h-full flex flex-col justify-center items-center">
			<div className="bg-white p-10 shadow-lg"
				style={{maxWidth: '500px', width: '100%'}}>
				<h3 className="text-3xl mb-5 text-accent-dark">Nastavení hesla</h3>
				<div className="flex flex-row justify-center">
					{tokenValid ? (
						<div className="flex flex-col gap-1">
							Username: {username}
							<div className="italic">
								Heslo musí obsahovat alespoň 1 písmeno, alespoň 1 číslo a musí
								mít alespoň 6 znaků.
							</div>
							<div className="flex flex-row items-center bg-white border-gray-300 border flex-1">
								<FaLock size={18} className="m-2" />
								<PasswordInput
									value={password}
									autoComplete="new-password"
									placeholder="Heslo"
									isStandalone={false}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<div className="flex flex-row items-center bg-white border-gray-300 border flex-1">
								<FaLock size={18} className="m-2" />
								<PasswordInput
									value={passwordVerify}
									autoComplete="new-password"
									isStandalone={false}
									placeholder="Heslo znovu"
									onChange={(e) => setPasswordVerify(e.target.value)}
								/>
							</div>
							<div className="flex flex-row gap-2 items-center mt-3">
								<button
									className="bg-accent-normal text-white rounded-full px-3 py-1 border-none hover:bg-accent-light"
									onClick={() => changePassword(password, token)}
								>
									Nastavit
								</button>
							</div>
						</div>) : (
						<div className="flex flex-col gap-1">
							<div className="italic">
								Zadejte token pro nastavení hesla, který jste obdrželi od správce. Pokud jste se sem dostali omylem, přejděte na <a href="/login" style={{color: 'blue', textDecoration: 'underline'}}>přihlašovací stránku</a>.
							</div>
							<div className="flex flex-row items-center bg-white border-gray-300 border flex-1">
								<FaKey size={18} className="m-2" />
								<input
									type="text"
									autoComplete="off"
									className="outline-none border-none grow"
									placeholder="token"
									value={tokenInput}
									onChange={(e) => setTokenInput(e.target.value)}
								/>
							</div>
							<div className="flex flex-row gap-2 items-center mt-3">
								<button
									className="bg-accent-normal text-white rounded-full px-3 py-1 border-none hover:bg-accent-light"
									onClick={() => setToken(tokenInput)}
								>
									Pokračovat
								</button>
							</div>
						</div>)
					}
				</div>
			</div>
		</div>
	);
};
