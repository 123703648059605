import {
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LayoutWithNav, LayoutWithoutNav } from './components/Utils/Layouts';
import { ProtectedRoute } from './components/Utils/ProtectedRoute';
import { API_BASE_PATH, APP_API_BASE_PATH } from './config';
import {
	checkIsPhone,
	DATA_PARAMETER_KEYS,
	POLLING_INTERVAL,
	SIGN_OUT_TIMEOUT,
} from './constants';
import AdminPanel from './pages/AdminPanel';
import Chart from './pages/Chart';
import Dashboard from './pages/Dashboard';
import Docs from './pages/Docs';
import Groups from './pages/Groups';
import LoginPage from './pages/Login';
import MapPage from './pages/Map';
import NotFound from './pages/NotFound';
import Sensors from './pages/Sensors';
import SetPasswordPage from './pages/SetPassword';
import Settings from './pages/Settings';
import SetupPage from './pages/Setup';
import {
	ApiResponse,
	AppConfig,
	AppLanguage,
	AppTheme,
	FetchDataApiResponse,
	FetchDataData,
	FetchDataDataWrapped,
	GenericApiResponse,
	GroupListApiResponse,
	LoginState,
	Sensor,
	SensorDataCache,
	SensorGroup,
	SensorListApiResponse,
	UserData,
	UserRights,
} from './types';
import { getUserRights } from './utils';

interface AppStateContextType {
	isPhone: boolean;
	appTheme: AppTheme;
	appLanguage: AppLanguage;
	apiEnabled: boolean;
	publicPageEnabled: boolean;
	fetchAppConfig: () => void;
}

interface DataContextType {
	sensorList: Sensor[];
	groupList: SensorGroup[];
	latestSensorData: FetchDataDataWrapped;
}

interface FunctionContextType {
	fetchSensorList: () => void;
	fetchGroupList: () => void;
	getSensorDataInPeriod: (
		sensorIds: number[],
		from: number,
		to: number
	) => Promise<FetchDataDataWrapped>;
}

interface AuthContextType {
	isAuth: boolean | null;
	isInited: boolean | null;
	setIsInited: React.Dispatch<React.SetStateAction<boolean | null>>;
	login: (username: string, password: string) => Promise<void>;
	logout: () => void;
}

interface UserContextType {
	userData: UserData | null;
	userRights: UserRights | null;
	fetchUserData: () => void;
}

// @ts-ignore
export const AppStateContext = createContext<AppStateContextType>();
// @ts-ignore
export const DataContext = createContext<DataContextType>();
// @ts-ignore
export const FunctionContext = createContext<FunctionContextType>();
// @ts-ignore
export const AuthContext = createContext<AuthContextType>();
// @ts-ignore
export const UserContext = createContext<UserContextType>();

function App() {
	const [isAuth, setIsAuth] = useState<boolean>(false);
	const login = (username: string, password: string) => {
		return new Promise<void>((res, rej) => {
			const payload = {
				username,
				password,
			};
			fetch(APP_API_BASE_PATH + '/login', {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
				credentials: 'include',
			})
				.then((data) => data.json())
				.then((response: GenericApiResponse) => {
					if (response.status === 'err') throw new Error(response.message);
					setIsAuth(true);
					toast.success('Uživatel přihlášen');
					res();
				})
				.catch((e: Error) => {
					console.error(e);
					rej(e);
				});
		});
	};
	const logout = () => {
		setIsAuth(false);

		fetch(APP_API_BASE_PATH + '/logout', {
			method: 'POST',
			mode: 'cors',
			credentials: 'include',
		})
			.then((data) => data.json())
			.then((response: GenericApiResponse) => {
				if (response.status === 'err') throw new Error(response.message);
				toast.success('Uživatel odhlášen');
				setIsAuth(false);
			})
			.catch((e: Error) => {
				console.error(e);
				toast.error(e.message);
			});
	};

	const [pageVisible, setPageVisible] = useState<boolean>(true);
	const pageVisibleRef = useRef(pageVisible);
	pageVisibleRef.current = pageVisible;

	const getLoginState = () =>
		fetch(APP_API_BASE_PATH + '/loginstate', {
			mode: 'cors',
			credentials: 'include',
		})
			.then((d) => d.json())
			.then((d: GenericApiResponse) => {
				if (d.status === 'err') {
					console.error(d.message);
					toast.error(d.message);
					return null;
				}

				return d.data as LoginState;
			});

	const [isInited, setIsInited] = useState<boolean | null>(null);
	useEffect(() => {
		getLoginState().then((loginState) => {
			if (!loginState) return;

			setIsInited(loginState.isInited);
			if (loginState.isInited) setIsAuth(loginState.isLoggedIn);
		});
		document.onvisibilitychange = (_) => setPageVisible(!document.hidden);
		return () => {
			document.onvisibilitychange = null;
		};
	}, []);
	useEffect(() => {
		if (!isInited) return;
		fetchAppConfig();
	}, [isInited]);

	const [userData, setUserData] = useState<UserData | null>(null);
	const [userRights, setUserRights] = useState<UserRights | null>(null);

	const fetchUserData = () => {
		fetch(APP_API_BASE_PATH + '/userdata', {
			mode: 'cors',
			credentials: 'include',
		})
			.then((d) => d.json())
			.then((d: ApiResponse<UserData>) => {
				if (d.status === 'err') {
					console.error(d.message);
					return toast.error(d.message);
				}

				if (d.data) {
					setUserData(d.data);
					setUserRights(getUserRights(d.data));
				}
			});
	};
	useEffect(() => {
		if (!isAuth) setUserData(null);
		else fetchUserData();
	}, [isAuth]);

	useEffect(() => {
		if (!isAuth) return;
		if (!userData?.auto_logout_enabled) return;

		let timeout: NodeJS.Timeout | null = null;
		if (!pageVisible) timeout = setTimeout(logout, SIGN_OUT_TIMEOUT);

		return () => {
			if (timeout !== null) clearTimeout(timeout);
		};
	}, [isAuth, pageVisible, userData]);

	const [sensorList, setSensorList] = useState<Sensor[]>([]);
	const [groupList, setGroupList] = useState<SensorGroup[]>([]);

	const [sensorListLoading, setSensorListLoading] = useState(false);
	const [groupListLoading, setGroupListLoading] = useState(false);
	const [latestDataLoading, setLatestDataLoading] = useState(false);

	const [loaderVisible, setLoaderVisible] = useState(false);

	const fetchSensorList = useCallback(async (inBackground: boolean = false) => {
		if (!inBackground) setSensorListLoading(true);
		return new Promise<Sensor[]>((res, rej) =>
			fetch(APP_API_BASE_PATH + '/sensorlist', { credentials: 'include' })
				.then((data) => data.json())
				.then((response: SensorListApiResponse) => {
					if (response.status === 'err') throw new Error(response.message);

					const data = response.data.map((s) => {
						const lastMessageTimestamp = Math.max(
							s.data.temperature.timestamp,
							s.data.humidity.timestamp,
							s.data.rssi.timestamp,
							s.data.voltage.timestamp
						);
						s['last_response'] = lastMessageTimestamp;

						return s;
					});
					setSensorList(data);
					if (!inBackground) setSensorListLoading(false);
					res(data);
				})
				.catch((e: Error) => {
					console.error(e);
					toast.error(e.message);
				})
		);
	}, []);

	const fetchGroupList = useCallback((inBackground: boolean = false) => {
		if (!inBackground) setGroupListLoading(true);
		fetch(APP_API_BASE_PATH + '/grouplist', { credentials: 'include' })
			.then((data) => data.json())
			.then((response: GroupListApiResponse) => {
				if (response.status === 'err') throw new Error(response.message);

				setGroupList(response.data);
				if (!inBackground) setGroupListLoading(false);
			})
			.catch((e: Error) => {
				console.error(e);
				toast.error(e.message);
			});
	}, []);

	const [latestSensorData, setLatestSensorData] =
		useState<FetchDataDataWrapped>({});

	function fetchLatestSensorData(
		sensorIds: number[],
		inBackground: boolean = false
	) {
		if (!inBackground) setLatestDataLoading(true);
		let query = `?sensorId=${sensorIds.join(',')}`;

		return fetch(API_BASE_PATH + '/fetchdata' + query, {
			credentials: 'include',
		})
			.then((data) => data.json())
			.then((response: FetchDataApiResponse) => {
				if (response.status === 'err') throw new Error(response.message);

				setLatestSensorData(response.data.values);
				if (!inBackground) setLatestDataLoading(false);
			})
			.catch((e: Error) => {
				console.error(e);
				toast.error(e.message);
			});
	}

	const [cachedSensorsData, setCachedSensorsData] = useState<SensorDataCache>(
		{}
	);
	const cachedSensorsRef = useRef(cachedSensorsData);
	cachedSensorsRef.current = cachedSensorsData;

	const getSensorDataInPeriod = useCallback(
		async (sensorIds: number[], from: number, to: number) => {
			let query = `?sensorId=${sensorIds.join(',')}&from=${from}&to=${to}`;

			const cache = cachedSensorsRef.current;
			for (const [timestamps, values] of Object.entries(cache)) {
				const parts = timestamps.split('-');
				const cacheFrom = parseInt(parts[0]);
				const cacheTo = parseInt(parts[1]);

				if (cacheFrom <= from && cacheTo >= to) {
					let hasAllSensors = true;
					for (const sId of sensorIds) {
						const hasSensor = Object.keys(values).find(
							(v) => parseInt(v) === sId
						);
						if (hasSensor === undefined) {
							hasAllSensors = false;
							break;
						}
					}
					if (hasAllSensors) {
						const filteredValues: FetchDataDataWrapped = {};
						for (const [sId, data] of Object.entries(values)) {
							const hasSensor = sensorIds.findIndex((s) => s === parseInt(sId));
							if (hasSensor !== -1) {
								const filteredData: { [key: string]: any } = {};
								for (const key of DATA_PARAMETER_KEYS) {
									const timestamps = [];
									const values = [];
									for (const idx in data[key].timestamps) {
										const timestamp = data[key].timestamps[idx];
										const value = data[key].values[idx];

										if (timestamp >= from && timestamp <= to) {
											timestamps.push(timestamp);
											values.push(value);
										}
									}
									filteredData[key] = {
										timestamps,
										values,
									};
								}
								filteredValues[sId] = filteredData as FetchDataData;
							}
						}
						return filteredValues;
					}
				}
			}

			return new Promise<FetchDataDataWrapped>((res, rej) =>
				fetch(API_BASE_PATH + '/fetchdata' + query, { credentials: 'include' })
					.then((data) => data.json())
					.then((response: FetchDataApiResponse) => {
						if (response.status === 'err') throw new Error(response.message);

						const key = `${from}-${to}`;
						const values = response.data.values;
						setCachedSensorsData((oldCachedSensorData) => {
							const newCachedSensorData = { ...oldCachedSensorData };
							newCachedSensorData[key] = values;
							return newCachedSensorData;
						});
						res(values);
					})
					.catch((e: Error) => {
						console.error(e);
						toast.error(e.message);
					})
			);
		},
		[]
	);

	useEffect(() => {
		setLoaderVisible(
			sensorListLoading || groupListLoading || latestDataLoading
		);
	}, [sensorListLoading, groupListLoading, latestDataLoading]);

	const [apiEnabled, setApiEnabled] = useState<boolean | null>(null);
	const [publicPageEnabled, setPublicPageEnabled] = useState<boolean | null>(
		null
	);

	const fetchAppConfig = () => {
		fetch(APP_API_BASE_PATH + '/config', {
			mode: 'cors',
			credentials: 'include',
		})
			.then((d) => d.json())
			.then((d: ApiResponse<AppConfig>) => {
				if (d.status === 'err') {
					console.error(d.message);
					return toast.error(d.message);
				}
				if (d.data) {
					setApiEnabled(d.data.api_enabled);
					setPublicPageEnabled(d.data.public_page_enabled);
				}
			});
	};

	useEffect(() => {
		if (!isAuth && !publicPageEnabled) return;

		fetchSensorList().then((d) => {
			if (d.length === 0) return;
			fetchLatestSensorData(d.map((s) => s.sensor_id));
		});

		fetchGroupList();
	}, [fetchGroupList, fetchSensorList, isAuth, publicPageEnabled]);

	useEffect(() => {
		if ((!isAuth && !publicPageEnabled) || !pageVisible) return;
		if (!userData?.auto_update_enabled) return;

		const poolingLoop = setInterval(() => {
			fetchSensorList(true).then((d) => {
				if (d.length === 0) return;
				fetchLatestSensorData(
					d.map((s) => s.sensor_id),
					true
				);
			});
			fetchSensorList(true);
		}, POLLING_INTERVAL);

		return () => {
			clearInterval(poolingLoop);
		};
	}, [fetchSensorList, pageVisible, isAuth, publicPageEnabled, userData]);

	const [isPhone, setIsPhone] = useState(checkIsPhone());
	const isPhoneRef = useRef(isPhone);
	isPhoneRef.current = isPhone;

	useEffect(() => {
		window.onresize = () => {
			const ip = checkIsPhone();
			if (ip !== isPhoneRef.current) setIsPhone(ip);
		};
		return () => {
			window.onresize = null;
		};
	}, []);

	const [appTheme, setAppTheme] = useState(AppTheme.Light);
	const [appLanguage, setAppLanguage] = useState(AppLanguage.Czech);

	const appStateContextValue = useMemo(
		() =>
			({
				isPhone,
				appTheme,
				appLanguage,
				apiEnabled,
				publicPageEnabled,
				fetchAppConfig,
			} as AppStateContextType),
		[isPhone, appTheme, appLanguage, apiEnabled, publicPageEnabled]
	);

	const dataContextValue = useMemo(
		() => ({
			sensorList,
			groupList,
			latestSensorData,
		}),
		[sensorList, groupList, latestSensorData]
	);

	const functionContextValue = useMemo(
		() => ({
			fetchSensorList,
			fetchGroupList,
			getSensorDataInPeriod,
		}),
		[fetchSensorList, fetchGroupList, getSensorDataInPeriod]
	);

	if (isAuth === null || isInited === null) return null;
	if (isAuth && (userData === null || userRights === null)) return null;
	if (isInited && (publicPageEnabled === null || apiEnabled === null))
		return null;

	return (
		<div
			className={
				'app' + (userData?.color_scheme === AppTheme.Dark ? ' dark' : '')
			}
		>
			<AuthContext.Provider
				value={{ isAuth, isInited, setIsInited, login, logout }}
			>
				<UserContext.Provider value={{ userData, userRights, fetchUserData }}>
					<AppStateContext.Provider value={appStateContextValue}>
						<DataContext.Provider value={dataContextValue}>
							<FunctionContext.Provider value={functionContextValue}>
								<BrowserRouter>
									<Routes>
										<Route
											element={<LayoutWithNav loaderVisible={loaderVisible} />}
										>
											<Route
												index
												element={
													<>
														{/* <ProtectedRoute> */}
														{!isInited ? (
															<Navigate to="/setup" />
														) : !publicPageEnabled && !isAuth ? (
															<Navigate to="/login" />
														) : (
															<Dashboard />
														)}
														{/* </ProtectedRoute> */}
													</>
												}
											/>
											<Route
												path="map"
												element={
													<ProtectedRoute>
														<MapPage />
													</ProtectedRoute>
												}
											/>
											<Route
												path="sensors"
												element={
													<ProtectedRoute>
														<Sensors />
													</ProtectedRoute>
												}
											/>
											<Route
												path="groups"
												element={
													<ProtectedRoute>
														<Groups />
													</ProtectedRoute>
												}
											/>
											<Route
												path="chart"
												element={
													<ProtectedRoute>
														<Chart />
													</ProtectedRoute>
												}
											/>
											<Route
												path="settings"
												element={
													<ProtectedRoute>
														<Settings />
													</ProtectedRoute>
												}
											/>
											<Route
												path="admin"
												element={
													<ProtectedRoute>
														<AdminPanel />
													</ProtectedRoute>
												}
											/>
											{apiEnabled && <Route path="docs" element={<Docs />} />}
										</Route>
										<Route element={<LayoutWithoutNav loaderVisible={false} />}>
											<Route path="login" element={<LoginPage />} />
											<Route path="password" element={<SetPasswordPage />} />
											<Route path="setup" element={<SetupPage />} />
											<Route path="*" element={<NotFound />} />
										</Route>
									</Routes>
								</BrowserRouter>
								<ToastContainer style={{ zIndex: 999999 }} />
							</FunctionContext.Provider>
						</DataContext.Provider>
					</AppStateContext.Provider>
				</UserContext.Provider>
			</AuthContext.Provider>
		</div>
	);
}

export default App;
