import { useContext, useEffect, useState } from 'react';
import { MdInfoOutline, MdSave } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { UserContext } from '../App';
import { APP_API_BASE_PATH } from '../config';
import { POLLING_INTERVAL, SIGN_OUT_TIMEOUT } from '../constants';
import { AppLanguage, AppTheme, GenericApiResponse } from '../types';

function Settings() {
	const { userData, fetchUserData } = useContext(UserContext);
	const [selectedColorScheme, setSelectedColorScheme] = useState<AppTheme>(
		AppTheme.Light
	);
	const [autoLogoutEnabled, setAutoLogoutEnabled] = useState(true);
	const [autoUpdateEnabled, setAutoUpdateEnabled] = useState(true);

	useEffect(() => {
		if (userData === null) return;
		setSelectedColorScheme(userData.color_scheme);
		setAutoLogoutEnabled(userData.auto_logout_enabled);
		setAutoUpdateEnabled(userData.auto_update_enabled);
	}, [userData]);

	const changePreferences = async (
		colorScheme: AppTheme,
		autoLogoutEnabled: boolean,
		autoUpdateEnabled: boolean
	) => {
		const payload = {
			colorScheme,
			autoLogoutEnabled,
			autoUpdateEnabled,
		};

		return new Promise((res, rej) => {
			fetch(APP_API_BASE_PATH + '/editmypreferences', {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify(payload),
			})
				.then((d) => d.json())
				.then((d: GenericApiResponse) => {
					if (d.status === 'err') {
						console.error(d.message);
						toast.error(d.message);
						return rej(1);
					}
					res(0);
				});
		});
	};
	return (
		<>
			<div className="floating_buttons">
				<button
					onClick={() => {
						changePreferences(
							selectedColorScheme,
							autoLogoutEnabled,
							autoUpdateEnabled
						)
							.then(() => {
								fetchUserData();
								toast.success('Změny byly uloženy');
							})
							.catch((_) => {});
					}}
					title="Uložit změny"
				>
					<MdSave />
				</button>
			</div>
			<div>
				<h1 className="text-3xl mb-3">Nastavení</h1>
				<div className="flex flex-col gap-2">
					<div>
						<label htmlFor="color_scheme_select">Barevný režim</label>
						<select
							id="color_scheme_select"
							value={selectedColorScheme}
							onChange={(e) => setSelectedColorScheme(parseInt(e.target.value))}
						>
							<option value={AppTheme.Light}>Světlý</option>
							<option value={AppTheme.Dark}>Tmavý</option>
						</select>
					</div>
					<div>
						<label htmlFor="language_select">Jazyk aplikace</label>
						<select id="language_select">
							<option value={AppLanguage.Czech}>Česky</option>
							{/* <option value={AppLanguage.English}>English</option> */}
							{/* <option value={AppLanguage.Latin}>Latin</option> */}
						</select>
					</div>
					<div className="flex flex-row items-center">
						<input
							type="checkbox"
							id="auto_logout_enabled_checkbox"
							className="mr-2"
							checked={autoLogoutEnabled}
							onChange={(e) => setAutoLogoutEnabled(e.target.checked)}
						/>
						<label htmlFor="auto_logout_enabled_checkbox" className="mr-1">
							Automatické odhlášení
						</label>
						<MdInfoOutline data-tooltip-id="auto_logout_tooltip" className='dark:text-white' />
					</div>
					<div className="flex flex-row items-center">
						<input
							type="checkbox"
							id="auto_update_enabled_checkbox"
							className="mr-2"
							checked={autoUpdateEnabled}
							onChange={(e) => setAutoUpdateEnabled(e.target.checked)}
						/>
						<label htmlFor="auto_update_enabled_checkbox" className="mr-1">
							Automatická synchronizace dat
						</label>
						<MdInfoOutline data-tooltip-id="auto_update_tooltip" className='dark:text-white' />
					</div>
					{/* <p>Nastavení e-mailu pro notifikace</p> */}
					{/* <div>
						<button>Změna hesla</button>
					</div>
					<div>
						<button className="text-red-500 font-bold">Smazat účet</button>
					</div> */}
				</div>
			</div>
			<Tooltip
				id="auto_logout_tooltip"
				content={`Budete automaticky odhlášeni po ${
					SIGN_OUT_TIMEOUT / (60 * 1000)
				} minutách inaktivity (pokud stránka není viditelná).`}
				style={{ maxWidth: '400px' }}
				place="bottom"
			/>
			<Tooltip
				id="auto_update_tooltip"
				content={`Data jsou automaticky synchronizována se serverem každých ${
					POLLING_INTERVAL / 1000
				} sekund.`}
				style={{ maxWidth: '400px' }}
				place="bottom"
			/>
		</>
	);
}

export default Settings;
