const SERVER_HOST = window.location.hostname;

const USE_HTTP = true;
const PROTOCOL = USE_HTTP ? 'http' : 'https';
const DEVELOPMENT_SERVER_PORT = 4000; // 8080

const PORT_PART =
	process.env.NODE_ENV !== 'production' ? ':' + DEVELOPMENT_SERVER_PORT : '';

export const API_BASE_PATH = `${PROTOCOL}://${SERVER_HOST}${PORT_PART}/api`;
export const APP_API_BASE_PATH = `${PROTOCOL}://${SERVER_HOST}${PORT_PART}/app`;
