import { useState } from 'react';
import { toast } from 'react-toastify';
import { APP_API_BASE_PATH } from '../../config';
import { GenericApiResponse, UserData } from '../../types';
import CustomModal from '../CustomModal';

export interface ResetPasswordModalProps {
	isOpen: boolean;
	setIsOpen: (val: boolean) => void;
	onClose?: () => void;
	isOnPhone?: boolean;
	user?: UserData | null;
}
export default function ResetPasswordModal({
	isOpen,
	setIsOpen,
	onClose,
	isOnPhone,
	user,
}: ResetPasswordModalProps) {

	const [isNextStep, setIsNextStep] = useState(false);
	const [token, setToken] = useState('');

	const resetPassword = async (userId: string) => {
		const payload = {
			uId: userId,
		};

		return new Promise((res, rej) => {
			fetch(APP_API_BASE_PATH + '/resetpassword', {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify(payload),
			})
				.then((d) => d.json())
				.then((d: GenericApiResponse) => {
					if(d.status === 'err') {
						console.error(d.message);
						toast.error('Server vrátil chybu: ' + d.message);
						return rej(d.message);
					}
					if(d.status === 'ok') {
						if(d.data !== undefined) {
							toast.success('Heslo bylo úspěšně resetováno');
							res(d.data);
						} else {
							toast.error('Nepodařilo se resetovat heslo');
							res(null);
						}
					}
				});
		});
	};

	return (
		<>
			<CustomModal
				isOpen={isOpen}
				handleClose={() => setIsOpen(false)}
				onClose={onClose}
				onOpen={() => {
					setIsNextStep(false);
					setToken('');
				}}
				title={'Resetování hesla'}
				isFullscreen={isOnPhone}
				style={{
					content: {
						maxWidth: '450px',
						width: '100%',
						position: 'relative',
						inset: '0px',
					},
					overlay: {
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					},
				}}
				content={
					<div className="flex-grow flex flex-col overflow-hidden gap-1 mt-2 p-3">
						{isNextStep ? (
							<div>
								Heslo pro uživatele&nbsp;'<b>{user?.username}</b>' bylo úspěšně resetováno.<br />
								Token: {token}
								<br />
								<p>
									Tímto odkazem si uživatel může nastavit nové heslo:{' '}
									<a href={`/password?token=${token}`} style={{color: 'blue', textDecoration: 'underline'}}>{`${window.location.origin}/password?token=${token}`}</a>
								</p>
							</div>
						) : (
							<div>
								Chcete opravdu resetovat heslo pro uživatele&nbsp;'<b>{user?.username}</b>'?
							</div>
						)}
					</div>
				}
				footer={
					<div className="mt-3 flex flex-row gap-1">
						{!isNextStep && (
							<button
								className="bg-white text-accent-normal border-accent-normal border-2 border-solid rounded-full px-3 py-1 hover:border-accent-light"
								onClick={() => setIsOpen(false)}
							>
								Zavřít
							</button>
						)}
						<button
							className="bg-accent-normal text-white rounded-full px-3 py-1 border-2 border-transparent hover:bg-accent-light"
							onClick={async () => {
								if(isNextStep) {
									setIsOpen(false);
								}
								else {
									if(!user?.user_id) return;
									resetPassword(user.user_id).then((data: any) => {
										setToken(data.token);
										setIsNextStep(true);
										//invalidateUserData();
									});
								}
							}}
						>
							{isNextStep ? 'Zavřít' : 'Dále'}
						</button>
					</div>
				}
			/>
		</>
	);
};
