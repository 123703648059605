import { useCallback, useContext, useEffect, useState } from 'react';
import { FaLock, FaUser } from 'react-icons/fa';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../App';
import PasswordInput from '../components/PasswordInput';

export interface LoginPageProps {}

const AFTER_LOGIN_NAVIGATE = '/';
const LoginPage: React.FC<LoginPageProps> = () => {
	const navigate = useNavigate();
	const { isAuth, isInited, login } = useContext(AuthContext);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const performLogin = useCallback(() => {
		if (!username || !password)
			return toast.error('Přihlašovací údaje nebyly vyplněny');

		login(username, password)
			.then(() => navigate(AFTER_LOGIN_NAVIGATE))
			.catch((e) => toast.error(e.message));
	}, [login, navigate, password, username]);

	useEffect(() => {
		document.onkeydown = (event) => {
			if (event.key === 'Enter') {
				event.preventDefault();
				performLogin();
			}
		};
		return () => {
			document.onkeydown = null;
		};
	}, [performLogin]);

	if (!isInited) return <Navigate to={'/setup'} />;
	if (isAuth) return <Navigate to={AFTER_LOGIN_NAVIGATE} />;

	return (
		<div className="w-full h-full flex flex-col justify-center items-center">
			<div className="bg-white p-10 shadow-lg">
				<h1 className="text-3xl mb-5 text-accent-dark">IQAROS DASHBOARD</h1>
				<div className="flex flex-row justify-center">
					<div className="flex flex-col gap-1">
						<div className="flex flex-row items-center bg-white border-gray-300 border">
							<FaUser size={18} className="m-2" />
							<input
								type="text"
								value={username}
								className="outline-none border-0"
								placeholder="Uživatel"
								onChange={(e) => setUsername(e.target.value)}
							/>
						</div>
						<div className="flex flex-row items-center bg-white border-gray-300 border">
							<FaLock size={18} className="m-2" />
							<PasswordInput
								value={password}
								className="outline-none border-0"
								placeholder="Heslo"
								isStandalone={false}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						<div className="flex flex-row gap-2 items-center mt-3">
							<button
								className="bg-accent-normal text-white rounded-full px-3 py-1 border-none hover:bg-accent-light"
								onClick={() => performLogin()}
							>
								Login
							</button>
							{/* <Link to="/docs" className="link">
                Dokumentace
              </Link> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
