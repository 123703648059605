import { ConnectionDetails } from '../../types';
import CustomModal from '../CustomModal';

export interface MQTTCredentialsModalProps {
	isOpen: boolean;
	setIsOpen: (val: boolean) => void;
	onClose?: () => void;
	isOnPhone?: boolean;
	connDetails: ConnectionDetails;
}

const MQTTCredentialsModal: React.FC<MQTTCredentialsModalProps> = ({
	isOpen,
	setIsOpen,
	onClose,
	isOnPhone,
	connDetails,
}) => {
	return (
		<>
			<CustomModal
				isOpen={isOpen}
				handleClose={() => setIsOpen(false)}
				onClose={onClose}
				title="Údaje pro MQTT"
				isFullscreen={isOnPhone}
				style={{
					content: {
						maxWidth: '500px',
						width: '100%',
						position: 'relative',
						inset: '0px',
					},
					overlay: {
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					},
				}}
				content={
					<>
						<div className="flex-grow flex flex-col overflow-hidden gap-1 p-3">
							<div>
								<p>
									V konfiguračním panelu IQAROS gatewaye v záložce{' '}
									<b>Cloudové služby</b> změňte nastavení MQTT na:
								</p>
								<table className="mt-6">
									<tbody>
										<tr>
											<td className="pr-4 font-bold">Broker:</td>
											<td>{connDetails.brokerIp}</td>
										</tr>
										<tr>
											<td className="pr-4 font-bold">Klient ID:</td>
											<td>{connDetails.clientId}</td>
										</tr>
										<tr>
											<td className="pr-4 font-bold">Topic pro dotaz:</td>
											<td>{connDetails.pushTopic}</td>
										</tr>
										<tr>
											<td className="pr-4 font-bold">Topic pro odpověď:</td>
											<td>{connDetails.pullTopic}</td>
										</tr>
										<tr>
											<td className="pr-4 font-bold">Uživatel:</td>
											<td>{connDetails.mqttUser}</td>
										</tr>
										<tr>
											<td className="pr-4 font-bold">Heslo:</td>
											<td>{connDetails.mqttPasswrod}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</>
				}
				footer={null}
			/>
		</>
	);
};

export default MQTTCredentialsModal;
