import { InputHTMLAttributes, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {
	isStandalone?: boolean;
}
const PasswordInput: React.FC<PasswordInputProps> = ({
	isStandalone = true,
	...props
}) => {
	const [isShowingPassword, setIsShowingPassword] = useState(false);
	const [isFocused, setIsFocused] = useState(false);

	const Eye = isShowingPassword ? FaEyeSlash : FaEye;
	return (
		<div
			className={
				'flex flex-row flex-1 items-center' + (isStandalone ? ' border' : '')
			}
			style={{ borderColor: isFocused ? '#aaa' : '#d1d5db' }}
		>
			<input
				type={isShowingPassword ? 'text' : 'password'}
				className="outline-none border-0 flex-1"
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				{...props}
			/>
			<Eye
				size={20}
				className="mx-2 cursor-pointer"
				onClick={() => setIsShowingPassword(!isShowingPassword)}
			/>
		</div>
	);
};

export default PasswordInput;
