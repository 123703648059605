import { useState } from 'react';
import { toast } from 'react-toastify';
import { APP_API_BASE_PATH } from '../../config';
import { GenericApiResponse } from '../../types';
import CustomModal from '../CustomModal';

export interface AddUserModalProps {
	isOpen: boolean;
	setIsOpen: (val: boolean) => void;
	onClose?: () => void;
	invalidateUserData: () => void;
	isOnPhone?: boolean;
}

const AddUserModal: React.FC<AddUserModalProps> = ({
	isOpen,
	setIsOpen,
	onClose,
	invalidateUserData,
	isOnPhone,
}) => {
	const [username, setUsername] = useState<string>('');

	const [isNextStep, setIsNextStep] = useState(false);
	const [token, setToken] = useState('');

	const createUser = async (username: string) => {
		const payload = {
			username,
		};

		return new Promise((res, rej) => {
			if(!username) {
				rej('no username');
				return toast.error('Pole nesmí být prázdné');
			}

			fetch(APP_API_BASE_PATH + '/createuser', {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify(payload),
			})
				.then((d) => d.json())
				.then((d: GenericApiResponse) => {
					if(d.status === 'err') {
						console.error(d.message);
						toast.error('Server vrátil chybu: ' + d.message);
						return rej(d.message);
					}
					if(d.status === 'ok') {
						if(d.data !== undefined) {
							toast.success('Byl vytvořen nový uživatel');
							res(d.data);
						} else {
							toast.error('Nepodařilo se vytvořit nového uživatele');
							res(null);
						}
					}
				});
		});
	};

	return (
		<>
			<CustomModal
				isOpen={isOpen}
				handleClose={() => setIsOpen(false)}
				onClose={onClose}
				onOpen={() => {
					setUsername('');
				}}
				title={'Nový uživatel'}
				isFullscreen={isOnPhone}
				style={{
					content: {
						maxWidth: '450px',
						width: '100%',
						position: 'relative',
						inset: '0px',
					},
					overlay: {
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					},
				}}
				content={
					<div className="flex-grow flex flex-col overflow-hidden gap-1 mt-2 p-3">
						{isNextStep ? (
							<div>
								Uživatel&nbsp;'<b>{username}</b>' byl úspěšně vytvořen.<br />
								Token pro nastavení hesla: {token}
								<br />
								<p>
									Tímto odkazem si uživatel může nastavit nové heslo:{' '}
									<a href={`/password?token=${token}`} style={{color: 'blue', textDecoration: 'underline'}}>{`${window.location.origin}/password?token=${token}`}</a>
								</p>
							</div>
						) : (
							<div className="flex flex-col">
								<label>Uživatelské jméno</label>
								<input
									type="text"
									autoComplete="off"
									className="border border-gray-300 outline-none"
									placeholder="uzivatel"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div>
						)}
					</div>
				}
				footer={
					<div className="mt-3 flex flex-row gap-1">
						{!isNextStep && (
							<button
								className="bg-white text-accent-normal border-accent-normal border-2 border-solid rounded-full px-3 py-1 hover:border-accent-light"
								onClick={() => setIsOpen(false)}
							>
								Zavřít
							</button>
						)}
						<button
							className="bg-accent-normal text-white rounded-full px-3 py-1 border-2 border-transparent hover:bg-accent-light"
							onClick={async () => {
								if(isNextStep) {
									setIsOpen(false);
								}
								else {
									createUser(username).then((data: any) => {
										setToken(data.token);
										setIsNextStep(true);
										invalidateUserData();
									}).catch(e => console.error(e));
								}
							}}
						>
							{isNextStep ? 'Zavřít' : 'Dále'}
						</button>
					</div>
				}
			/>
		</>
	);
};

export default AddUserModal;
