import { useContext, useEffect, useState } from 'react';
import { MdAdd, MdInfoOutline, MdSave } from 'react-icons/md';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { AppStateContext, UserContext } from '../App';
import AddUserModal from '../components/Modals/AddUserModal';
import MQTTCredentialsModal from '../components/Modals/MQTTCredentialsModal';
import UserRow from '../components/UserRow';
import { APP_API_BASE_PATH } from '../config';
import {
	ApiResponse,
	ConnectionDetails,
	GenericApiResponse,
	UserData,
} from '../types';
import ResetPasswordModal from '../components/Modals/ResetPasswordModal';

function AdminPanel() {
	const { userData } = useContext(UserContext);
	const { isPhone, apiEnabled, publicPageEnabled, fetchAppConfig } =
		useContext(AppStateContext);
	const [users, setUsers] = useState<UserData[]>([]);
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

	const fetchUserList = () => {
		fetch(APP_API_BASE_PATH + '/userlist', {
			mode: 'cors',
			credentials: 'include',
		})
			.then((d) => d.json())
			.then((d: ApiResponse<UserData[]>) => {
				if (d.status === 'err') {
					console.error(d.message);
					return toast.error(d.message);
				}
				if (d.data) setUsers(d.data);
			});
	};
	const [isApiEnabled, setIsApiEnabled] = useState(apiEnabled);
	const [isPublicPageEnabled, setIsPublicPageEnabled] =
		useState(publicPageEnabled);

	const [connDetails, setConnDetails] = useState<ConnectionDetails | null>(
		null
	);

	useEffect(() => {
		fetchUserList();
		fetch(APP_API_BASE_PATH + '/connectiondetails', {
			mode: 'cors',
			credentials: 'include',
		})
			.then((d) => d.json())
			.then((d: ApiResponse<ConnectionDetails>) => {
				if (d.status === 'err') {
					console.error(d.message);
					return toast.error(d.message);
				}
				d.data && setConnDetails(d.data);
			});
	}, []);

	useEffect(() => {
		if (!hasUnsavedChanges) return;
		window.onbeforeunload = () => {
			return false;
		};
		return () => {
			window.onbeforeunload = null;
		};
	}, [hasUnsavedChanges]);

	const editUsers = async (users: UserData[]) => {
		const payload = {
			users: users.map((u) => ({
				uId: u.user_id,
				is_admin: u.is_admin,
				can_edit_tiles: u.can_edit_tiles,
				can_edit_groups: u.can_edit_groups,
				can_edit_sensors: u.can_edit_sensors,
				can_edit_maps: u.can_edit_maps,
			})),
		};
		return new Promise((res, rej) => {
			fetch(APP_API_BASE_PATH + '/editusers', {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify(payload),
			})
				.then((d) => d.json())
				.then((d: GenericApiResponse) => {
					if (d.status === 'err') {
						console.error(d.message);
						toast.error(d.message);
						return res(1);
					}
					if (d.status === 'ok') {
						if (d.data !== undefined) toast.success('Změny byly uloženy');
						else toast.error('Nepodařilo se uložit změny');
						res(0);
					}
				});
		});
	};

	const removeUser = async (uId: string) => {
		const payload = {
			uId,
		};

		return new Promise((res, rej) => {
			fetch(APP_API_BASE_PATH + '/removeuser', {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify(payload),
			})
				.then((d) => d.json())
				.then((d: GenericApiResponse) => {
					if (d.status === 'err') {
						console.error(d.message);
						toast.error(d.message);
						return rej(1);
					}
					res(0);
				});
		});
	};

	const changeConfig = async (
		newApiEnabled: boolean,
		newPublicPageEnabled: boolean
	) => {
		const payload = {
			apiEnabled: newApiEnabled,
			publicPageEnabled: newPublicPageEnabled,
		};

		return new Promise((res, rej) => {
			fetch(APP_API_BASE_PATH + '/changeconfig', {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify(payload),
			})
				.then((d) => d.json())
				.then((d: GenericApiResponse) => {
					if (d.status === 'err') {
						console.error(d.message);
						toast.error(d.message);
						return rej(1);
					}
					res(0);
				});
		});
	};

	const [selectedTab, setSelectedTab] = useState<'users' | 'options'>('users');
	const [isUserModalOpen, setIsUserModalOpen] = useState(false);
	const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
	const [isMQTTConnModalOpen, setIsMQTTConnModalOpen] = useState(false);

	const [resettingUser, setResettingUser] = useState<UserData | null>(null);

	if (userData === null) return null;
	if (!userData.is_root && !userData.is_admin) return <Navigate to={'/'} />;

	return (
		<>
			<div className="h-full flex flex-col">
				<h1 className="text-3xl mb-3">Admin panel</h1>
				<div className="flex flex-row border-b-2 border-b-accent-normal text-xl mb-4">
					<button
						className={
							'outline-none border-0 px-2 ' +
							(selectedTab === 'users'
								? 'hover:bg-accent-normal bg-accent-normal text-white'
								: '')
						}
						onClick={() => setSelectedTab('users')}
					>
						Správa uživatelů
					</button>
					<button
						className={
							'outline-none border-0 px-2 ' +
							(selectedTab === 'options'
								? 'hover:bg-accent-normal bg-accent-normal text-white'
								: '')
						}
						onClick={() => setSelectedTab('options')}
					>
						Nastavení
					</button>
				</div>
				{selectedTab === 'users' ? (
					<>
						<div className="floating_buttons">
							{hasUnsavedChanges && (
								<button
									onClick={() => {
										editUsers(users).then(() => setHasUnsavedChanges(false));
									}}
									title="Uložit změny"
								>
									<MdSave />
								</button>
							)}
							<button
								onClick={() => setIsUserModalOpen(true)}
								title="Nový uživatel"
							>
								<MdAdd />
							</button>
						</div>
						<div className="styled_table flex-1">
							<table>
								<thead>
									<tr>
										<td>Uživatelské jméno</td>
										<td>
											Správa senzorů
											<MdInfoOutline
												className="inline ml-1"
												data-tooltip-id="can_edit_sensors"
											/>
										</td>
										<td>
											Správa dlaždic
											<MdInfoOutline
												className="inline ml-1"
												data-tooltip-id="can_edit_tiles"
											/>
										</td>
										<td>
											Správa skupin
											<MdInfoOutline
												className="inline ml-1"
												data-tooltip-id="can_edit_groups"
											/>
										</td>
										<td>
											Správa map
											<MdInfoOutline
												className="inline ml-1"
												data-tooltip-id="can_edit_maps"
											/>
										</td>
										<td>
											Admin
											<MdInfoOutline
												className="inline ml-1"
												data-tooltip-id="is_admin"
											/>
										</td>
										<td>Datum vytvoření</td>
										<td></td>
									</tr>
								</thead>
								<tbody>
									{users.map((u) => (
										<UserRow
											key={u.user_id}
											user={u}
											editUser={(newUser) => {
												const newUsers = users.map((x) => {
													if (x.user_id === u.user_id) return newUser;
													return x;
												});
												setUsers(newUsers);
												setHasUnsavedChanges(true);
											}}
											handleRemove={() => {
												const prompt = window.confirm(
													`Opravdu si přejete odstranit uživatele ${u.username}?`
												);
												if (!prompt) return;

												removeUser(u.user_id)
													.then(() => {
														toast.success(
															`Uživatel ${u.username} byl odstraněn`
														);
														fetchUserList();
													})
													.catch((_) => {});
											}}
											handleResetPassword={() => {
												setResettingUser(u);
												setIsResetPasswordModalOpen(true);
											}}
											isMe={u.user_id === userData.user_id}
										/>
									))}
								</tbody>
							</table>
						</div>
					</>
				) : (
					<>
						<div className="floating_buttons">
							<button
								onClick={() => {
									changeConfig(isApiEnabled, isPublicPageEnabled)
										.then(() => {
											fetchAppConfig();
											toast.success('Změny byly uloženy');
										})
										.catch((_) => {});
								}}
								title="Uložit změny"
							>
								<MdSave />
							</button>
						</div>
						<div className="flex flex-col gap-2">
							<div>
								<input
									type="checkbox"
									id="api_enabled"
									className="mr-2"
									checked={isApiEnabled}
									onChange={(e) => setIsApiEnabled(e.target.checked)}
								/>
								<label htmlFor="api_enabled">Veřejné API zapnuté</label>
							</div>
							<div className="flex flex-row items-center">
								<input
									type="checkbox"
									id="public_page_enabled"
									className="mr-2"
									checked={isPublicPageEnabled}
									onChange={(e) => setIsPublicPageEnabled(e.target.checked)}
								/>
								<label htmlFor="public_page_enabled" className="mr-1">
									Veřejná stránka
								</label>
								<MdInfoOutline data-tooltip-id="public_page_enabled_label" />
							</div>
							<div>
								<button onClick={() => setIsMQTTConnModalOpen(true)}>
									Zobrazit údaje pro MQTT
								</button>
							</div>
							{/* <p>Změnit údaje pro MQTT</p> */}
							{/* <p>Export/import databáze</p> */}
							{/* <p>Reset do továrního nastavení</p> */}
						</div>
						<Tooltip
							id="public_page_enabled_label"
							content="Pozor, při používání veřejné stránky bude automaticky spuštěno i veřejné API - kdokoli bude moci stahovat data z libovolných senzorů. Uživatelé s oprávněním Správa dlaždic budou moci upravovat, které dlaždice budou veřejně dostupné."
							style={{ maxWidth: '300px' }}
							place="bottom"
						/>
					</>
				)}
			</div>
			<Tooltip
				id="can_edit_sensors"
				content="Může přejmenovávat senzory"
				style={{ maxWidth: '300px' }}
				place="bottom"
			/>
			<Tooltip
				id="can_edit_tiles"
				content="Může přidávat, mazat a upravovat dlaždice na úvodní obrazovce"
				style={{ maxWidth: '300px' }}
				place="bottom"
			/>
			<Tooltip
				id="can_edit_groups"
				content="Může přidávat, mazat a upravovat skupiny. Může přidávat a odebírat senzory ze skupin."
				style={{ maxWidth: '300px' }}
				place="bottom"
			/>
			<Tooltip
				id="can_edit_maps"
				content="Může přidávat, mazat a upravovat mapy. Může přidávat a mazat senzory z map a měnit pozice senzorů na mapě."
				style={{ maxWidth: '300px' }}
				place="bottom"
			/>
			<Tooltip
				id="is_admin"
				content="Má všechna nižší oprávnění + může přidávat a mazat uživatele a měnit jejich oprávnění. Má přístup ke globálnímu nastavení aplikace."
				style={{ maxWidth: '300px' }}
				place="bottom"
			/>
			<Tooltip
				id="admin_crown"
				content="Super uživatel, správce hesel"
				style={{ maxWidth: '300px' }}
				place="bottom"
			/>
			<AddUserModal
				isOpen={isUserModalOpen}
				setIsOpen={setIsUserModalOpen}
				invalidateUserData={() => fetchUserList()}
				isOnPhone={isPhone}
			/>
			<ResetPasswordModal
				isOpen={isResetPasswordModalOpen}
				setIsOpen={setIsResetPasswordModalOpen}
				isOnPhone={isPhone}
				user={resettingUser}
			/>
			{connDetails !== null && (
				<MQTTCredentialsModal
					isOpen={isMQTTConnModalOpen}
					setIsOpen={setIsMQTTConnModalOpen}
					connDetails={connDetails}
					isOnPhone={isPhone}
				/>
			)}
		</>
	);
}

export default AdminPanel;
