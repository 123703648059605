import { useContext, useEffect, useState } from 'react';
import { MdAdd, MdEdit, MdEditOff } from 'react-icons/md';
import { AppStateContext, AuthContext, DataContext, UserContext } from '../App';
import DashboardTile from '../components/DashboardTile';

import { toast } from 'react-toastify';
import DashboardModal from '../components/Modals/DashboardModal';
import { APP_API_BASE_PATH } from '../config';
import { GenericApiResponse, Tile, TileListApiResponse } from '../types';

function Dashboard() {
	const { isPhone } = useContext(AppStateContext);
	const { userRights, userData } = useContext(UserContext);
	const { isAuth } = useContext(AuthContext);
	const data = useContext(DataContext);

	const [tileList, setTileList] = useState<Tile[]>([]);

	const fetchTileList = () => {
		fetch(APP_API_BASE_PATH + '/tilelist', { credentials: 'include' })
			.then((data) => data.json())
			.then((parsed_data) => {
				const response = parsed_data as TileListApiResponse;
				if (response.status === 'err') throw new Error(response.message);

				setTileList(response.data);
			})
			.catch((e: Error) => {
				console.error(e);
				toast.error(e.message);
			});
	};

	useEffect(() => {
		fetchTileList();
	}, []);

	function removeTile(tileId: number) {
		const payload = {
			tileId,
		};
		fetch(APP_API_BASE_PATH + '/removetile', {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
			credentials: 'include',
		})
			.then((data) => data.json())
			.then((response: GenericApiResponse) => {
				if (response.status === 'err') throw new Error(response.message);

				fetchTileList();
				toast.success('Dlaždice byla odstraněna');
			})
			.catch((e: Error) => {
				console.error(e);
				toast.error(e.message);
			});
	}

	async function moveTile(ID: number, orderDelta: number) {
		return new Promise((res, rej) => {
			const payload = {
				ID,
				orderDelta,
			};
			fetch(APP_API_BASE_PATH + '/movetile', {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
				credentials: 'include',
			})
				.then((data) => data.json())
				.then((response: GenericApiResponse) => {
					if (response.status === 'err') throw new Error(response.message);
					res(null);
				})
				.catch((e: Error) => {
					console.error(e);
					toast.error(e.message);
					rej(e)
				});
		});
	}

	const [isEditing, setIsEditing] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [editedTile, setEditedTile] = useState<Tile | null>(null);

	return (
		<>
			{isAuth && userRights?.can_edit_tiles && (
				<div className="floating_buttons">
					{tileList.length > 0 && (
						<button
							onClick={() => {
								setIsEditing(!isEditing);
							}}
							style={{ fontSize: '30px' }}
							title={isEditing ? 'Zrušit úpravu dlaždic' : 'Upravovat dlaždice'}
						>
							{isEditing ? <MdEditOff /> : <MdEdit />}
						</button>
					)}
					<button
						onClick={() => {
							setEditedTile(null);
							setModalIsOpen(true);
						}}
						title="Nová dlaždice"
					>
						<MdAdd />
					</button>
				</div>
			)}
			{tileList.length > 0 ? (
				<div className="flex flex-row flex-wrap gap-2 p-8 overflow-y-auto justify-center">
					{tileList.map((t, idx) => (
						<DashboardTile
							tile={t}
							sensorData={data.latestSensorData}
							groupList={data.groupList}
							isEditing={isEditing}
							handleRemove={() => {
								const prompt = window.confirm(
									`Opravdu si přejete smazat dlaždici ${t.title}?`
								);
								if (prompt) removeTile(t.ID);
							}}
							handleEdit={() => {
								setEditedTile(t);
								setModalIsOpen(true);
							}}
							handleOrderChange={(delta) => {
								const maxOrder = Math.max(...tileList.map(x => x.order));
								const newOrder = t.order + delta;
								if(newOrder < 0 || newOrder > maxOrder) return;
								moveTile(t.ID, delta)
									.then(() => fetchTileList())
									.catch((_) => {});
							}}
							colorScheme={userData?.color_scheme}
							key={idx}
						/>
					))}
				</div>
			) : (
				<div className="w-full h-full flex flex-col items-center text-3xl">
					<span className="pt-20">
						Žádné dlaždice
						{isAuth && (
							<>
								<span>&nbsp;-&nbsp;</span>
								<span
									className="link"
									onClick={() => {
										setEditedTile(null);
										setModalIsOpen(true);
									}}
								>
									přidat
								</span>
							</>
						)}
					</span>
				</div>
			)}
			<DashboardModal
				isOpen={modalIsOpen}
				setIsOpen={setModalIsOpen}
				tileList={tileList}
				fetchTileList={fetchTileList}
				editedTile={editedTile}
				isOnPhone={isPhone}
				setIsEditing={setIsEditing}
			/>
		</>
	);
}

export default Dashboard;
