import { DisplayParameter, DisplayedSensor, SensorData } from '../types';
import { formatSensorData } from '../utils';

export interface DisplayedSensorSVGProps {
	sensor: DisplayedSensor;
	displayParameter: DisplayParameter;
	color: string;
}

export default function DisplayedSensorSVG({sensor, displayParameter, color}: DisplayedSensorSVGProps) {
	
	const sensorDataObject: SensorData = {
		temperature: sensor.data?.temperature.value,
		humidity: sensor.data?.humidity.value,
		rssi: sensor.data?.rssi.value,
		voltage: sensor.data?.voltage.value,
	};

	const radius = 20;
	const displayText =
		displayParameter === DisplayParameter.Name
		? sensor.sensor.sensor_name
		: formatSensorData(sensorDataObject, displayParameter);

	return (
		<svg width="40" height="40" className="overflow-visible" style={{
			transform: 'translate(-50%, -50%)',
		}}>
			<g className="cursor-pointer select-none">
				<circle
					cx={radius}
					cy={radius}
					r={radius}
					fill={displayParameter === DisplayParameter.Name ? 'black' : color}
				/>
				<text
					x={radius}
					y={radius}
					textAnchor="middle"
					fill="white"
					fontSize={11}
					strokeWidth="0.3px"
					stroke="black"
					fontWeight="bold"
					dy=".3em"
					style={{ background: 'black' }}
				>
					{displayText}
				</text>
			</g>
		</svg>
	);
};
