import { useContext } from 'react';
import {
	FaChartLine,
	FaFileAlt,
	FaHome,
	FaLayerGroup,
	FaMap,
	FaThermometerThreeQuarters,
	FaUsersCog,
} from 'react-icons/fa';
import { MdLogin, MdLogout, MdSettings } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppStateContext, AuthContext, UserContext } from './App';
function Nav() {
	const { isAuth, logout } = useContext(AuthContext);
	const { userData } = useContext(UserContext);
	const { apiEnabled, publicPageEnabled } = useContext(AppStateContext);

	const location = useLocation();
	const navigate = useNavigate();
	return (
		<nav>
			<div className="flex flex-col gap-2">
				{(isAuth || publicPageEnabled) && (
					<Link to="/" className={location.pathname === '/' ? 'active' : ''}>
						<FaHome title="Domů" />
						<div className="title">Domů</div>
					</Link>
				)}
				{isAuth && (
					<>
						<Link
							to="sensors"
							className={location.pathname === '/sensors' ? 'active' : ''}
						>
							<FaThermometerThreeQuarters title="Senzory" />
							<div className="title">Senzory</div>
						</Link>
						<Link
							to="groups"
							className={location.pathname === '/groups' ? 'active' : ''}
						>
							<FaLayerGroup title="Skupiny" />
							<div className="title">Skupiny</div>
						</Link>
						<Link
							to="map"
							className={location.pathname === '/map' ? 'active' : ''}
						>
							<FaMap title="Mapa" />
							<div className="title">Mapy</div>
						</Link>
						<Link
							to="chart"
							className={location.pathname === '/chart' ? 'active' : ''}
						>
							<FaChartLine title="Graf" />
							<div className="title">Graf</div>
						</Link>
						<Link
							to="settings"
							className={location.pathname === '/settings' ? 'active' : ''}
						>
							<MdSettings title="Nastavení" />
							<div className="title">Nastavení</div>
						</Link>
						{(userData?.is_root || userData?.is_admin) && (
							<Link
								to="admin"
								className={location.pathname === '/admin' ? 'active' : ''}
							>
								<FaUsersCog title="Admin" />
								<div className="title">Admin</div>
							</Link>
						)}
					</>
				)}
				{apiEnabled && (
					<Link
						to="docs"
						className={location.pathname === '/docs' ? 'active' : ''}
					>
						<FaFileAlt title="Dokumentace" />
						<div className="title">Dokumentace</div>
					</Link>
				)}
			</div>

			<div className="mb-4">
				{isAuth ? (
					<button
						onClick={() => logout()}
						className="bg-transparent border-none hover:bg-transparent text-white hover:text-gray-300"
					>
						<MdLogout title="Odhlášení" size={30} />
					</button>
				) : (
					<button
						onClick={() => navigate('/login')}
						className="bg-transparent border-none hover:bg-transparent text-white hover:text-gray-300"
					>
						<MdLogin title="Přihlášení" size={30} />
					</button>
				)}
			</div>
		</nav>
	);
}

export default Nav;
