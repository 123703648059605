import { FaCrown } from 'react-icons/fa';
import { MdDelete, MdOutlineLockReset } from 'react-icons/md';
import { UserData } from '../types';

export interface UserRowProps {
	user: UserData;
	editUser: (newData: UserData) => void;
	handleRemove: () => void;
	handleResetPassword: () => void;
	isMe: boolean;
}

const UserRow: React.FC<UserRowProps> = ({
	user,
	editUser,
	handleRemove,
	handleResetPassword,
	isMe,
}) => {
	return (
		<tr>
			<td
				className="flex flex-row items-center gap-2"
				style={{ fontWeight: isMe ? 'bold' : 'normal' }}
			>
				{user.username}
				{user.is_root && <FaCrown title="Admin" data-tooltip-id="admin_crown" />}
			</td>
			<td>
				<input
					type="checkbox"
					checked={user.can_edit_sensors}
					disabled={user.is_root || isMe}
					onChange={(e) =>
						editUser({ ...user, can_edit_sensors: e.target.checked })
					}
				/>
			</td>
			<td>
				<input
					type="checkbox"
					checked={user.can_edit_tiles}
					disabled={user.is_root || isMe}
					onChange={(e) =>
						editUser({ ...user, can_edit_tiles: e.target.checked })
					}
				/>
			</td>
			<td>
				<input
					type="checkbox"
					checked={user.can_edit_groups}
					disabled={user.is_root || isMe}
					onChange={(e) =>
						editUser({ ...user, can_edit_groups: e.target.checked })
					}
				/>
			</td>
			<td>
				<input
					type="checkbox"
					checked={user.can_edit_maps}
					disabled={user.is_root || isMe}
					onChange={(e) =>
						editUser({ ...user, can_edit_maps: e.target.checked })
					}
				/>
			</td>
			<td>
				<input
					type="checkbox"
					checked={user.is_admin}
					disabled={user.is_root || isMe}
					onChange={(e) => editUser({ ...user, is_admin: e.target.checked })}
				/>
			</td>
			<td>{new Date(user.date_created).toLocaleDateString()}</td>
			<td className="flex flex-row gap-1 text-2xl">
				{(!user.is_root || isMe) && (
					<MdOutlineLockReset
						className="cursor-pointer"
						onClick={handleResetPassword}
						title="Změnit heslo"
					/>
				)}
				{!user.is_root && !isMe && (
					<MdDelete
						className="cursor-pointer"
						onClick={handleRemove}
						title="Odstranit uživatele"
					/>
				)}
			</td>
		</tr>
	);
};

export default UserRow;
